import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, provideZoneChangeDetection } from "@angular/core";
import { provideRouter, Router } from "@angular/router";
import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { AuthClientConfig, AuthHttpInterceptor, provideAuth0 } from "@auth0/auth0-angular";
import { ServerConfigService } from "@app/server-config/server-config.service";
import * as Sentry from "@sentry/angular";

export function initializeAuthConfig(serverConfigService: ServerConfigService, authClientConfig: AuthClientConfig): () => Promise<void> {
  return async () => {
    const authConfig = await serverConfigService.getAuthConfig();
    authClientConfig.set(authConfig);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAuth0(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuthConfig,
      deps: [ServerConfigService, AuthClientConfig],
      multi: true
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true
    }
  ]
};
