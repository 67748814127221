import {Component, Input, output} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";

export enum ButtonType {
  // ThreeDX = 'hamburger--3dx',
  // ThreeDXR = 'hamburger--3dx-r',
  // ThreeDY = 'hamburger--3dy',
  // ThreeDYR = 'hamburger--3dy-r',
  // ThreeDXY = 'hamburger--3dxy',
  // ThreeDXYR = 'hamburger--3dxy-r',
  // Arrow = 'hamburger--arrow',
  // ArrowR = 'hamburger--arrow-r',
  // ArrowAlt = 'hamburger--arrowalt',
  // ArrowAltR = 'hamburger--arrowalt-r',
  // ArrowTurn = 'hamburger--arrowturn',
  // ArrowTurnR = 'hamburger--arrowturn-r',
  // Boring = 'hamburger--boring',
  // Collapse = 'hamburger--collapse',
  // CollapseR = 'hamburger--collapse-r',
  Elastic = 'hamburger--elastic',
  // ElasticR = 'hamburger--elastic-r',
  // Emphatic = 'hamburger--emphatic',
  // EmphaticR = 'hamburger--emphatic-r',
  // Minus = 'hamburger--minus',
  // Slider = 'hamburger--slider',
  // SliderR = 'hamburger--slider-r',
  // Spin = 'hamburger--spin',
  // SpinR = 'hamburger--spin-r',
  // Spring = 'hamburger--spring',
  // SpringR = 'hamburger--spring-r',
  // Stand = 'hamburger--stand',
  // StandR = 'hamburger--stand-r',
  // Squeeze = 'hamburger--squeeze',
  // Vortex = 'hamburger--vortex',
  // VortexR = 'hamburger--vortex-r',
}

@Component({
  selector: 'app-hamburger-button',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './hamburger-button.component.html',
  styleUrls: ['./hamburger-button.component.scss']
})
export class HamburgerButtonComponent {
  @Input() buttonType: ButtonType = ButtonType.Elastic;
  @Input() isChecked: boolean = false;
  isCheckedChange = output<boolean>();

  click() {
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }
}
