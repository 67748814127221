<mat-form-field>
  @if (label()) {
    <mat-label>{{ label() }}</mat-label>
  }
  <mat-select
    #control="ngModel"
    [placeholder]="placeholder()"
    [(ngModel)]="value"
    (ngModelChange)="valid.set(control.errors === null)"
    [required]="required()"
    [disabled]="disabled()">
    @for (option of optionValues(); track option) {
      <mat-option [value]="option.value">{{ option.name }}</mat-option>
    }
  </mat-select>
  @if (control.errors && control.errors['required']) {
    <mat-error>Required.</mat-error>
  }
</mat-form-field>
