import { inject, Injectable } from "@angular/core";
import {environment} from "@environment";
import {AuthService, User} from "@auth0/auth0-angular";
import {UserRole, UserID} from "@models/Users";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private readonly authService = inject(AuthService);

  constructor() {
    if (environment.local && localStorage.getItem('useLocalBackend') === 'true') {
      this._fileServerURL = "http://localhost:3000"
    } else {
      this._fileServerURL = environment.serverURL;
    }
  }

  private _fileServerURL: string;

  /**
   * Get the file server URL
   * @returns {string} - The file server URL
   */
  get fileServerURL(): string {
    return this._fileServerURL;
  }

  getUserID(): Observable<UserID | undefined> {
    return this.authService.user$.pipe(
      map((user: User | undefined | null) => {
        if (user === null || user === undefined) {
          return undefined;
        } else {
          return user['illumica_user_id'] ?? undefined;
        }
      })
    );
  }

  getOrganizationID(): Observable<string | undefined> {
    return this.authService.user$.pipe(
      map((user: User | undefined | null) => {
        if (user === null || user === undefined) {
          return undefined;
        } else {
          return user['illumica_organization_id'] ?? undefined;
        }
      })
    );
  }

  getRole(): Observable<UserRole | undefined> {
    return this.authService.user$.pipe(
      map((user: User | undefined | null) => {
        if (user === null || user === undefined) {
          return undefined;
        } else {
          return user['illumica_role'] as UserRole ?? undefined;
        }
      })
    );
  }
}
