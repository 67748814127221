@if ((isAuth0Loading$ | async)) {
  <div class="page">
    <app-loading></app-loading>
  </div>
} @else {
  <mat-sidenav-container class="page">
    <mat-sidenav [opened]="sidenavOpen" mode="side">
      <div class="sidenav-content">
        <div ariaCurrentWhenActive="page" class="logo-container" routerLinkActive="active">
          <img alt="Illumica AI" src="assets/images/illumica.ai.png" width="100">
        </div>
        <mat-nav-list class="dashboard-navigation">
          @for (route of routes; track route) {
            <mat-divider></mat-divider>
            <mat-list-item ariaCurrentWhenActive="page" [routerLink]="route.path" routerLinkActive="active">
              <fa-icon [icon]="route.icon" matListItemIcon [fixedWidth]="true" size="sm"></fa-icon>
              <a matListItemTitle>{{route.title}}</a>
            </mat-list-item>
          }
        </mat-nav-list>
        <!--        <mat-slide-toggle (change)="themeService.toggleTheme($event.checked)" [checked]="themeService.defaultTheme === 'dark'" color="accent">-->
        <!--          <mat-icon>bedtime</mat-icon>-->
      <!--        </mat-slide-toggle>-->
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      @if(isAuthenticated$ | async) {
        <app-hamburger-button [(isChecked)]="sidenavOpen"></app-hamburger-button>
        <div class="spacer"></div>
        <app-profile-button></app-profile-button>
      } @else {
        <h1>FitPal</h1>
        <div class="spacer"></div>
        <app-login-button color="primary"></app-login-button>
      }
    </mat-toolbar>
    <router-outlet></router-outlet>
    @if(isLocal) {
      <div class="developer-options">
        <h2>Developer Options</h2>
        <app-select
          label="API Selection"
          [value]="serverConfig"
          (valueChange)="onServerConfigChange($event)"
          [options]="serverConfigs" />
        </div>
      }
    </mat-sidenav-content>
  </mat-sidenav-container>
}

