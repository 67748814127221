import { Component, inject } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatMenuItem } from "@angular/material/menu";
import { RouterLink } from "@angular/router";
import { RouterService } from "@services/router.service";

@Component({
  selector: 'app-settings-menu-item',
  standalone: true,
  imports: [
    MatIcon,
    MatMenuItem,
    RouterLink
  ],
  templateUrl: './settings-menu-item.component.html',
  styleUrl: './settings-menu-item.component.scss'
})
export class SettingsMenuItemComponent {
  protected router = inject(RouterService);
  protected route = this.router.routeSettings();
}
