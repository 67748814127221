import { Component, inject } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-logout-menu-item',
  standalone: true,
  imports: [
    MatIconModule,
    MatMenuModule
  ],
  templateUrl: './logout-menu-item.component.html',
  styleUrl: './logout-menu-item.component.scss'
})
export class LogoutMenuItemComponent {
  protected document = inject<Document>(DOCUMENT);

  private authService = inject(AuthService);

  logout(): void {
    this.authService.logout({
      logoutParams: {
        returnTo: this.document.location.origin
      }
    });
  }

}
