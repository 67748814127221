<!--<div class="img-wrapper">-->
<img [matMenuTriggerFor]="menu" [src]="(user$ | async)?.picture" alt="profile_picture"/>
<!--</div>-->

<mat-menu #menu="matMenu">
  @if (role === UserRoles.PATIENT) {
    <button ariaCurrentWhenActive="page" mat-menu-item [routerLink]="router.routePatientProfile()" routerLinkActive="active">
      <mat-icon class="material-symbols-outlined outlined">account_circle</mat-icon>
      Profile
    </button>
    <mat-divider></mat-divider>
  }
  @if (role === UserRoles.THERAPIST || role === UserRoles.ADMIN) {
    <button ariaCurrentWhenActive="page" mat-menu-item [routerLink]="router.routeCaregiverProfile()" routerLinkActive="active">
      <mat-icon class="material-symbols-outlined outlined">account_circle</mat-icon>
      Profile
    </button>
    <mat-divider></mat-divider>
  }
  <app-settings-menu-item></app-settings-menu-item>
  <mat-divider></mat-divider>
  <app-logout-menu-item></app-logout-menu-item>
</mat-menu>
