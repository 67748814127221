import { Component, inject, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { ThemeService } from "@services/theme.service";
import { UserRole, UserRoles } from "@models";
import { firstValueFrom } from "rxjs";
import { StateService } from "@services/state.service";
import {
  faDumbbell,
  faSquarePollVertical,
  faBuildingUser,
  faHouse,
  faCubesStacked,
  faCopy
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FilterMatchMode, PrimeNGConfig } from "primeng/api";
import { RouterService } from "@services/router.service";
import { environment } from "@environment";
import { ServerConfigService } from "@app/server-config/server-config.service";
import { ServerConfig } from "@app/server-config/server-config";
import { SelectComponent } from "@components/forms/fields/select/select.component";
import { LoginButtonComponent } from "@components/buttons/login-button/login-button.component";
import { ProfileButtonComponent } from "@components/buttons/profile-button/profile-button.component";
import { HamburgerButtonComponent } from "@components/buttons/hamburger-button/hamburger-button.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { RouterLinkActive, RouterLink, RouterOutlet } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { LoadingComponent } from "@components/templates/loading/loading.component";
import { AsyncPipe, CommonModule } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    MatSidenavModule,
    MatListModule,
    LoadingComponent,
    RouterLinkActive,
    MatDividerModule,
    RouterLink,
    FaIconComponent,
    MatToolbarModule,
    HamburgerButtonComponent,
    ProfileButtonComponent,
    LoginButtonComponent,
    RouterOutlet,
    SelectComponent,
    AsyncPipe,
    CommonModule
  ]
})
export class AppComponent implements OnInit {
  protected readonly serverConfigService = inject(ServerConfigService);
  protected readonly authService = inject(AuthService);
  protected readonly themeService = inject(ThemeService);
  protected readonly primengConfig = inject(PrimeNGConfig);
  protected readonly stateService = inject(StateService);
  protected readonly router = inject(RouterService);

  protected readonly serverConfigs: ServerConfig[] = ["local", "development", "production"];
  protected serverConfig: ServerConfig = "local";
  protected sidenavOpen = false;
  protected isAuth0Loading$ = this.authService.isLoading$;
  protected isAuthenticated$ = this.authService.isAuthenticated$;
  protected isLocal = environment.local;
  protected role: UserRole | undefined = undefined;
  protected routes: { path: string, title: string, icon: IconDefinition }[] = [];

  async ngOnInit() {
    this.serverConfig = await this.serverConfigService.getServerConfig();
    this.themeService.toggleTheme(true);
    this.primengConfig.ripple = true;
    this.primengConfig.zIndex = {
      modal: 1100,    // dialog, sidebar
      overlay: 1000,  // dropdown, overlaypanel
      menu: 1000,     // overlay menus
      tooltip: 1100   // tooltip
    };
    this.primengConfig.filterMatchModeOptions = {
      text: [FilterMatchMode.STARTS_WITH, FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.ENDS_WITH, FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
      numeric: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN, FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
      date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER]
    };

    this.role = await firstValueFrom(this.stateService.getRole());

    await this.router.initialize();

    switch (this.role) {
      case UserRoles.SUPER_ADMIN:
        this.routes = [
          { path: this.router.routeDashboard(), title: "Dashboard", icon: faHouse },
          { path: this.router.routeOrganizations(), title: "Organizations", icon: faBuildingUser },
          { path: this.router.routeExercises(), title: "Exercises", icon: faDumbbell },
          { path: this.router.routeFunctionalTests(), title: "Functional Tests", icon: faDumbbell },
          { path: this.router.routeSurveys(), title: "Surveys", icon: faSquarePollVertical },
          { path: this.router.routeComponents(), title: "Components", icon: faCubesStacked },
          { path: this.router.routeCaptureConfiguration(), title: "Configure Capture", icon: faDumbbell }
        ];
        break;
      case UserRoles.ADMIN:
        this.routes = [
          { path: this.router.routeDashboard(), title: "Dashboard", icon: faHouse },
          { path: this.router.routeOrganizationDetails(), title: "My Organization", icon: faBuildingUser },
          { path: this.router.routeProgramTemplates(), title: "HEP Templates", icon: faCopy },
          { path: this.router.routeExercises(), title: "Exercises", icon: faDumbbell },
          { path: this.router.routeFunctionalTests(), title: "Functional Tests", icon: faDumbbell },
          { path: this.router.routeSurveys(), title: "Surveys", icon: faSquarePollVertical }
        ];
        break;
      case UserRoles.THERAPIST:
        this.routes = [
          { path: this.router.routeDashboard(), title: "Dashboard", icon: faHouse },
          { path: this.router.routeOrganizationDetails(), title: "My Organization", icon: faBuildingUser },
          { path: this.router.routeProgramTemplates(), title: "HEP Templates", icon: faCopy },
          { path: this.router.routeExercises(), title: "Exercises", icon: faDumbbell },
          { path: this.router.routeFunctionalTests(), title: "Functional Tests", icon: faDumbbell },
          { path: this.router.routeSurveys(), title: "Surveys", icon: faSquarePollVertical }
        ];
        break;
      case UserRoles.PATIENT:
        this.routes = [
          { path: this.router.routeDashboard(), title: "Dashboard", icon: faHouse }
        ];
    }
  }

  protected async onServerConfigChange(serverConfig: ServerConfig | undefined) {
    if (serverConfig) {
      await this.setServerConfig(serverConfig);
    }
  }

  protected setServerConfig(serverConfig: ServerConfig) {
    return this.serverConfigService.setServerConfig(serverConfig);
  }
}
