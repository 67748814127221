import { Component, Input } from "@angular/core";
import {MatProgressSpinner} from '@angular/material/progress-spinner'

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [
    MatProgressSpinner
  ],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {
  @Input() debounceTime: number = 250;

  private _visible: boolean = false;
  protected get visible(): boolean { return this._visible; }
  private set visible(value: boolean) { this._visible = value; }

  ngOnInit() {
    setTimeout(() => {
      this.visible = true;
    }, this.debounceTime);
  }
}
