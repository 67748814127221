import { Component, OnInit, inject } from '@angular/core'
import { AsyncPipe, DOCUMENT } from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {AuthService} from "@auth0/auth0-angular";
import {AvatarPipe} from "@app/pipes/avatar.pipe";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink, RouterLinkActive} from '@angular/router'
import { StateService } from '@app/services/state.service';
import { firstValueFrom } from 'rxjs';
import {UserRole, UserRoles} from '@models'
import {RouterService} from "@services/router.service";
import { LogoutMenuItemComponent } from "@components/menus/menu-items/logout-menu-item/logout-menu-item.component";
import {
  SettingsMenuItemComponent
} from "@components/menus/menu-items/settings-menu-item/settings-menu-item.component";

@Component({
  selector: 'app-profile-button',
  standalone: true,
  imports: [MatMenuModule, AvatarPipe, MatListModule, MatIconModule, RouterLink, RouterLinkActive, LogoutMenuItemComponent, SettingsMenuItemComponent, AsyncPipe],
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss']
})
export class ProfileButtonComponent implements OnInit {
  private _authService = inject(AuthService);
  private document = inject<Document>(DOCUMENT);
  private stateService = inject(StateService);
  router = inject(RouterService);


  readonly user$ = this._authService.user$;
  role: UserRole | undefined;

  async ngOnInit() {
    this.role = await firstValueFrom(this.stateService.getRole())
  }

  logout(): void {
    this._authService.logout({
      logoutParams: {
        returnTo: this.document.location.origin
      }
    });
  }

  protected readonly UserRoles = UserRoles;
}
