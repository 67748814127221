export type UserRole = 'Super Admin' | 'Admin' | 'Therapist' | 'Patient';
export type CaregiverRole = 'Admin' | 'Therapist';
export type UserID = string;

export const UserRoles = {
    SUPER_ADMIN: 'Super Admin' as 'Super Admin',
    ADMIN: 'Admin' as 'Admin',
    THERAPIST: 'Therapist' as 'Therapist',
    PATIENT: 'Patient' as 'Patient',
} as const;

export const CaregiverRoles = {
    ADMIN: 'Admin' as 'Admin',
    THERAPIST: 'Therapist' as 'Therapist',
} as const;

export * from "./patient";
export * from "./caregiver";
