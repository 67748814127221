import { Component, Input, inject } from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {AuthService} from "@auth0/auth0-angular";

@Component({
  selector: 'app-login-button',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './login-button.component.html',
})
export class LoginButtonComponent {
  private auth = inject(AuthService);

  @Input() color: 'primary' | 'accent' | 'warn' | null = null;
  @Input() disabled = false;

  handleLogin(): void {
    this.auth.loginWithRedirect({
      appState: {
        target: '/',
      },
    });
  }
}
