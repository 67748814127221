
export type CameraView = 'Front' | 'Side' | 'Back';
export const CameraViews: CameraView[] = ['Front', 'Side', 'Back'];

export type ExercisePosition = 'Standing' | 'Sitting' | 'Lying' | 'Kneeling' | 'Prone' | 'Supine';
export const ExercisePositions: ExercisePosition[] = ['Standing', 'Sitting', 'Lying', 'Kneeling', 'Prone', 'Supine'];

export type JointID = number;
export type EquipmentID = number;
export type ExerciseID = number;

export interface Joint {
  id: JointID;
  name: string;
}

export interface Equipment {
  id: EquipmentID;
  name: string;
}

export interface ExerciseEquipmentCreation {
  equipment_id: EquipmentID;
}

export interface ExerciseEquipment extends ExerciseEquipmentCreation {
  equipment_name: string;
}

export interface ExerciseJointCreation {
  joint_id: JointID;
  default_min_rom_threshold: number | null;
  default_max_rom_threshold: number | null;
  default_min_rep_threshold: number | null;
  default_max_rep_threshold: number | null;
}

export interface ExerciseJoint extends ExerciseJointCreation {
  joint_name: string;
}

export interface ExerciseCreation {
  name: string;
  short_description: string | null;
  long_description: string | null;

  video_url: string | null;
  equipment: number[];
  camera_view: CameraView;
  position: ExercisePosition;

  joints: ExerciseJointCreation[];
  default_sets: number;

  reps_enabled: boolean;
  rom_enabled: boolean;

  default_reps?: number;
  default_time?: number;

  rep_thresholds?: { minAngle: number, maxAngle: number}[];
  rom_thresholds?: { minAngle: number, maxAngle: number}[];
}

export interface Exercise {
  id: ExerciseID;
  name: string;
  short_description: string;
  long_description: string;

  video: string | null;
  preview: string | null;
  thumbnail: string | null;

  reps_enabled: boolean;
  rom_enabled: boolean;
  default_sets: number;

  default_reps?: number;
  default_time?: number;

  camera_view: CameraView;
  exercise_position: ExercisePosition;

  equipment: ExerciseEquipment[];
  joints: ExerciseJoint[];

  created_at?: Date;
  updated_at?: Date;
}

export interface ExerciseFilters {
  pageParams?: {
    page: number;
    limit: number;
  };
  search?: string;
  camera_view?: CameraView;
  position?: ExercisePosition;
  reps_enabled?: boolean;
  rom_enabled?: boolean;
}
