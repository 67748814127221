import {LogLevels} from "@services/misc/logger.service";

export const environment = {
  production: false,
  local: false,
  LogLevel: LogLevels.debug,

  title: "FitPal Dev",
  namespace: 'https://fitpal.illumica.ai',
  serverURL: 'https://dev.api.illumica.co',

  sentryDsn: "https://1988c15f27bd7af158d2071f8e0292d3@o4508236307169280.ingest.us.sentry.io/4508449823653888",
};


