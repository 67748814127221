import { Injectable } from '@angular/core';
import {environment} from "@environment";

export type LogLevel = 'debug' | 'info' | 'warn' | 'error';
export const LogLevels = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
} as const;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private readonly level: number = LogLevels.info;

  constructor() {
    this.level = environment.LogLevel;
  }

  debug(message: unknown) {
    if (this.level <= LogLevels.debug) {
      this.log('debug', message);
    }
  }

  info(message: unknown) {
    if (this.level <= LogLevels.info) {
      this.log('info', message);
    }
  }

  warn(message: unknown) {
    if (this.level <= LogLevels.warn) {
      this.log('warn', message);
    }
  }

  error(message: unknown) {
    if (this.level <= LogLevels.error) {
      this.log('error', message);
    }
  }

  private log(level: LogLevel, message: unknown) {
    console[level](message);
  }
}
