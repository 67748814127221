import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { StateService } from "@services/state.service";
import { firstValueFrom } from "rxjs";
import { OrganizationID, UserRole, UserID } from "@models";

@Injectable({
  providedIn: "root"
})
export class RouterService {
  private readonly state = inject(StateService);
  private readonly router = inject(Router);


  private initialized = false;
  private organization_id: OrganizationID | undefined;
  private user_id: UserID | undefined;
  private role: UserRole | undefined;

  async initialize(): Promise<void> {
    if (this.initialized) {
      return;
    }

    this.user_id = await firstValueFrom(this.state.getUserID());
    this.organization_id = await firstValueFrom(this.state.getOrganizationID());
    this.role = await firstValueFrom(this.state.getRole());

    this.state.getUserID().subscribe(user_id => this.user_id = user_id);
    this.state.getOrganizationID().subscribe(organization_id => this.organization_id = organization_id);
    this.state.getRole().subscribe(role => this.role = role);

    this.initialized = true;
  }

  /**
   * Get the route to the home page.
   * @returns The route to the home page.
   */
  routeHome(): string {
    return "/";
  }

  /**
   * Get the route to the dashboard.
   * @returns The route to the dashboard.
   */
  routeDashboard(): string {
    return `/dashboard`;
  }

  /**
   * Get the route to the organization list.
   * @returns The route to the organization list.
   */
  routeOrganizations(): string {
    return "/organizations";
  }

  /**
   * Get the route to the organization details.
   * @param organization_id
   */
  routeOrganizationDetails(organization_id?: OrganizationID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    return `/organizations/${organization_id}`;
  }

  /**
   * Get the route to a user's profile.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @param user_id The user ID. Defaults to the user's id.
   * @returns The route to the user's profile.
   */
  routeCaregiverProfile(organization_id?: OrganizationID, user_id?: UserID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    if (!user_id) {
      user_id = this.user_id;
    }
    return `/organizations/${organization_id}/users/${user_id}/profile`;
  }

  /**
   * Get the route to a patient's profile.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @param patient_id The patient ID. Defaults to the user's id.
   * @returns The route to the patient's profile.
   */
  routePatientProfile(organization_id?: OrganizationID, patient_id?: UserID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    if (!patient_id) {
      patient_id = this.user_id;
    }
    return `/organizations/${organization_id}/patients/${patient_id}/profile`;
  }

  routeProgramBuilder(organization_id?: OrganizationID, caregiver_id?: UserID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    if (!caregiver_id) {
      caregiver_id = this.user_id;
    }
    return `/organizations/${organization_id}/caregivers/${caregiver_id}/assign-programs`;
  }

  routeProgramTemplates(organization_id?: OrganizationID, caregiver_id?: UserID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    if (!caregiver_id) {
      caregiver_id = this.user_id;
    }
    return `/organizations/${organization_id}/caregivers/${caregiver_id}/program-templates`;
  }

  routeProgramTemplateBuilder(organization_id?: OrganizationID, caregiver_id?: UserID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    if (!caregiver_id) {
      caregiver_id = this.user_id;
    }
    return `/organizations/${organization_id}/caregivers/${caregiver_id}/program-templates/build`;
  }

  routeProgramTemplateInfo(program_id: number, organization_id?: OrganizationID, caregiver_id?: UserID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    if (!caregiver_id) {
      caregiver_id = this.user_id;
    }
    return `/organizations/${organization_id}/caregivers/${caregiver_id}/program-templates/${program_id}/info`;
  }

  /**
   * Get the router to a program's info.
   * @param patient_id The patient ID.
   * @param program_id The program ID.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @returns The route to a program's info.
   */
  routeProgramInfo(patient_id: UserID, program_id: number, organization_id?: OrganizationID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    return `/organizations/${organization_id}/patients/${patient_id}/programs/${program_id}`;
  }

  /**
   * Get the route to the program runner.
   * @param program_id The program ID.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @param patient_id The patient ID. Defaults to the user's id.
   * @returns The route to the program runner.
   */
  routeProgramRunner(program_id: number, organization_id?: OrganizationID, patient_id?: UserID): string {
    if (!organization_id) {
      organization_id = this.organization_id;
    }
    if (!patient_id) {
      patient_id = this.user_id;
    }
    return `/organizations/${organization_id}/patients/${patient_id}/programs/${program_id}/run`;
  }

  /**
   * Get the route to the exercise list.
   * @returns The route to the exercise list.
   */
  routeExercises(): string {
    return "/exercises";
  }

  /**
   * Get the route to the exercise creator.
   * @returns The route to the exercise creator.
   */
  routeExerciseCreator(): string {
    return "/exercises/create";
  }

  /**
   * Get the route to the functional tests list.
   * @returns The route to the functional tests list.
   */
  routeFunctionalTests(): string {
    return "/functional-tests";
  }

  /**
   * Get the route to the exercise creator.
   * @returns The route to the exercise creator.
   */
  routeFunctionalTestCreator(): string {
    return "/functional-tests/create";
  }

  /**
   * Get the route to the survey list.
   * @returns The route to the survey list.
   */
  routeSurveys(): string {
    return "/surveys";
  }

  /**
   * Get the route to the survey list.
   * @returns The route to the survey list.
   */
  routeSurveyBuilder(organization_id?: OrganizationID, caregiver_id?: UserID, survey_id?: string): string {
    let route = "";
    if (organization_id) {
      route += `/organizations/${organization_id}`;
      if (caregiver_id) {
        route += `/caregivers/${caregiver_id}`;
      }
    }

    route += "/surveys";

    if (survey_id) {
      route += `/${survey_id}`;
    }

    return `${route}/build`;
  }

  /**
   * Get the route to the settings page.
   * @returns The route to the settings page.
   */
  routeSettings(): string {
    return "/settings";
  }

  /**
   * Get the route to the components page.
   * @returns The route to the components page.
   */
  routeComponents(): string {
    return "/components";
  }

  /**
   * Get the route to the capture configuration page.
   * @returns The route to the capture configuration page.
   */
  routeCaptureConfiguration(): string {
    return "/capture-configuration";
  }

  private routeSurveyRunner(organizationID: OrganizationID | undefined, patientID: UserID | undefined, programID: number | undefined, surveyID: string) {
    return `/organizations/${organizationID}/patients/${patientID}/programs/${programID}/surveys/${surveyID}/run`;
  }

  /**
   * Navigate to the home page.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToHome(): Promise<boolean> {
    return this.navigate(this.routeHome());
  }

  /**
   * Navigate to a dashboard.
   * @returns A promise that resolves when the navigation is complete.
   */
  async navigateToDashboard(): Promise<boolean> {
    return this.navigate(this.routeDashboard());
  }

  /**
   * Navigate to the organization list.
   * @returns A promise that resolves when the navigation is complete.
   */
  async navigateToOrganizations(): Promise<boolean> {
    return this.navigate(this.routeOrganizations());
  }

  /**
   * Navigate to the organization details.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @returns A promise that resolves when the navigation is complete.
   */
  async navigateToOrganizationDetails(organization_id?: OrganizationID): Promise<boolean> {
    return this.navigate(this.routeOrganizationDetails(organization_id));
  }

  /**
   * Navigate to a user's profile.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @param user_id The user ID. Defaults to the user's id.
   * @returns A promise that resolves when the navigation is complete.
   */
  async navigateToCaregiverProfile(organization_id?: OrganizationID, user_id?: UserID): Promise<boolean> {
    return this.navigate(this.routeCaregiverProfile(organization_id, user_id));
  }

  /**
   * Navigate to a patient's profile.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @param patient_id The patient ID. Defaults to the user's id.
   * @returns A promise that resolves when the navigation is complete.
   */
  async navigateToPatientProfile(organization_id?: OrganizationID, patient_id?: UserID): Promise<boolean> {
    return this.navigate(this.routePatientProfile(organization_id, patient_id));
  }

  navigateToProgramBuilder(organization_id?: OrganizationID, caregiver_id?: UserID): Promise<boolean> {
    return this.navigate(this.routeProgramBuilder(organization_id, caregiver_id));
  }

  navigateToProgramTemplates(organization_id?: OrganizationID, caregiver_id?: UserID): Promise<boolean> {
    return this.navigate(this.routeProgramTemplates(organization_id, caregiver_id));
  }

  navigateToProgramTemplateBuilder(organization_id?: OrganizationID, caregiver_id?: UserID): Promise<boolean> {
    return this.navigate(this.routeProgramTemplateBuilder(organization_id, caregiver_id));
  }

  /**
   * Navigate to a program's info.
   * @param patient_id The patient ID.
   * @param program_id The program ID.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @returns A promise that resolves when the navigation is complete.
   */
  async navigateToProgramInfo(patient_id: UserID, program_id: number, organization_id?: OrganizationID): Promise<boolean> {
    return this.navigate(this.routeProgramInfo(patient_id, program_id, organization_id));
  }

  /**
   * Navigate to the program runner.
   * @param program_id The program ID.
   * @param organization_id The organization ID. Defaults to the user's organization.
   * @param patient_id The patient ID. Defaults to the user's id.
   */
  async navigateToProgramRunner(program_id: number, organization_id?: OrganizationID, patient_id?: UserID): Promise<boolean> {
    return this.navigate(this.routeProgramRunner(program_id, organization_id, patient_id));
  }

  /**
   * Navigate to the exercise list.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToExercises(): Promise<boolean> {
    return this.navigate(this.routeExercises());
  }

  /**
   * Navigate to the exercise creator.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToExerciseCreator(): Promise<boolean> {
    return this.navigate(this.routeExerciseCreator());
  }

  /**
   * Navigate to the functional tests list.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToFunctionalTests(): Promise<boolean> {
    return this.navigate(this.routeFunctionalTests());
  }

  /**
   * Navigate to the functional test creator.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToFunctionalTestCreator(): Promise<boolean> {
    return this.navigate(this.routeFunctionalTestCreator());
  }


  /**
   * Navigate to the survey list.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToSurveys(): Promise<boolean> {
    return this.navigate(this.routeSurveys());
  }

  /**
   * Navigate to the survey list.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToSurveyBuilder(
    params?: {
      organization_id?: OrganizationID,
      caregiver_id?: UserID,
      survey_id?: string
    }
  ): Promise<boolean> {
    return this.navigate(this.routeSurveyBuilder(params?.organization_id, params?.caregiver_id, params?.survey_id));
  }

  /**
   * Navigate to the settings page.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToSettings(): Promise<boolean> {
    return this.navigate(this.routeSettings());
  }

  /**
   * Navigate to the components page.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToComponents(): Promise<boolean> {
    return this.navigate(this.routeComponents());
  }

  /**
   * Navigate to the capture configuration page.
   * @returns A promise that resolves when the navigation is complete.
   */
  navigateToCaptureConfiguration(): Promise<boolean> {
    return this.navigate(this.routeCaptureConfiguration());
  }


  async navigateToSurveyRunner(organizationID: OrganizationID | undefined, patientID: UserID | undefined, programID: number | undefined, surveyID: string) {
    return this.navigate(this.routeSurveyRunner(organizationID, patientID, programID, surveyID));
  }

  /**
   * Navigate to a given URL.
   * @param url
   * @private
   */
  private async navigate(url: string): Promise<boolean> {
    const success = await this.router.navigate([url]);
    if (!success) {
      console.error(`Failed to navigate to ${url}`);
    }
    return success;
  }
}


